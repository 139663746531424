import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import { router } from '@/router/index'
import ApiServiceV2 from '../../api/new/services/v2/api.service'

const defaultState = () => {
  return {
    translators: [],
    matchedTranslatorsSource: null,
    matchedTranslatorsDocument: {
      filename: null,
      words: 0
    },
    matchedTranslators: [],
    pages: {
      current: 0,
      total: 0
    },
    filter: {
      source: null,
      target: null,
      service: null
    },
    selectedTranslator: {},
    selectedTranslators: {
      translation: [],
      review: [],
      qa: []
    },
    combinedLanguages: [],
    selectedLanguages: {
      source: '',
      targets: []
    },
    selectedService: ''
  }
}

const state = defaultState()

const mutations = {
  setTranslators(state, translators) {
    state.translators = translators
  },
  setPages(state, page) {
    state.pages.current = page.current_page
    state.pages.total = page.last_page
  },
  setFilter(state, filter) {
    state.filter.source = filter.source
    state.filter.target = filter.target
    state.filter.service = filter.service
  },
  setMatchedTranslators(state, translators) {
    state.matchedTranslators = translators
  },
  setMatchedTranslatorsSource(state, source) {
    state.matchedTranslatorsSource = source
  },
  setMatchedTranslatorsDocument(state, document) {
    state.matchedTranslatorsDocument = document
  },
  setSelectedTranslator(state, translator) {
    state.selectedTranslator = translator
  },
  setSelectedTranslators(state, { translators, type }) {
    state.selectedTranslators[type] = translators
  },
  setCombinedLanguages(state, combo) {
    state.combinedLanguages = combo
  },
  resetState(state) {
    Object.assign(state, defaultState())
  },
  setSelectedLanguages(state, { source, targets }) {
    state.selectedLanguages = {
      source: source,
      targets: targets
    }
  },
  setSelectedService(state, service) {
    state.selectedService = service
  }
}

const actions = {
  async getStateTranslators({ dispatch, state }, payload) {
    if (state.translators.length === 0 || state.pages.current != 1)
      await dispatch('getTranslators', payload)
  },
  async getTranslators(
    { commit, rootGetters },
    { page, source, target, service }
  ) {
    const params = new URLSearchParams()
    if (source) {
      params.append(`filters[source_language][]`, source)
    }
    if (target) {
      params.append(`filters[target_language][]`, target)
    }
    if (service) {
      params.append(`filters[service][]`, service)
    }

    params.append('page', page)
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-team`,
      {
        params
      }
    )
      .then(async (res) => {
        const translators = res.data.data
        const pages = res.data.meta
        await commit('setTranslators', translators)
        await commit('setPages', pages)
        await commit('setFilter', { source, target, service })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateServices({ dispatch, state, rootGetters }, { id, services }) {
    const data = {
      data: {
        type: 'supplier',
        id,
        attributes: {
          services
        }
      }
    }

    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-team/${id}`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.saved_title'),
            message: i18n.t('customer.toast.success.update_prices')
          },
          { root: true }
        )
        await dispatch('getTranslators', {
          currentPage: state.pages.current,
          source: state.filter.source,
          target: state.filter.target,
          service: state.filter.service
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteLanguagePair({ dispatch, state, rootGetters }, { id, services }) {
    const data = {
      data: {
        type: 'translation-team',
        id,
        attributes: {
          services
        }
      }
    }

    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-team/${id}/remove`,
      data
    )
      .then(async () => {
        await dispatch('getTranslators', {
          currentPage: state.pages.current,
          source: state.filter.source,
          target: state.filter.target,
          service: state.filter.service
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async findTranslators({ commit, rootGetters }, payload) {
    commit('setSelectedLanguages', {
      source: payload.source,
      targets: payload.targets
    })
    commit('setSelectedService', payload.service)
    const data = new FormData()
    try {
      data.append('service', payload.service)
      data.append('area_of_expertise', payload.area_of_expertise)
      for (let i = 0; i < payload.targets.length; i++) {
        data.append('target_languages[]', payload.targets[i])
      }
      data.append(`source_language`, payload.source)
    } catch (err) {
      console.log(err)
    } finally {
      await ApiService.postFormData(
        `teams/${rootGetters['workspace/currentAccountId']}/translation-team/suggest`,
        data
      )
        .then(async (res) => {
          const matchedTranslators = res.data.data.attributes.target_languages

          if (matchedTranslators[0].suppliers.length == 0) {
            router.push({ name: 'clientTeamStepFive' })
          }
          const sourceLang = res.data.data.attributes.source_language
          const document = {
            filename: res.data.data.attributes.filename,
            words: res.data.data.attributes.words
          }
          const combos = res.data.data.attributes.combined_languages
          commit('setMatchedTranslators', matchedTranslators)
          commit('setMatchedTranslatorsSource', sourceLang)
          commit('setMatchedTranslatorsDocument', document)
          commit('setCombinedLanguages', combos)
          if (Object.values(matchedTranslators[0].suppliers).length > 0) {
            if (payload.service === 'copyediting') {
              router.push({ name: 'clientTeamStepCopywriting' })
            } else {
              router.push({ name: 'clientTeamStepTwo' })
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
          throw new Error(JSON.stringify(err))
        })
    }
  },
  async completeOnboarding({ commit, rootGetters }, payload) {
    const formData = new FormData()
    payload.files.forEach((file, index) => {
      formData.append(`data[attributes][files][${index}]`, file)
    })
    formData.append('data[type]', 'projects')
    formData.append(
      'data[attributes][source_language]',
      payload.source_language
    )
    payload.target_languages.forEach((language, index) => {
      formData.append(`data[attributes][target_languages][${index}]`, language)
    })

    formData.append('data[attributes][service]', payload.service)
    formData.append(
      'data[attributes][area_of_expertise]',
      payload.area_of_expertise
    )
    if (payload.instructions) {
      formData.append(
        'data[attributes][customer_instructions]',
        payload.instructions
      )
    }

    await ApiServiceV2.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/onboarding`,
      formData
    )
      .then(() => {})
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createTranslatorsTeam({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation-team',
        attributes: {
          suppliers: payload
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-team`,
      data
    )
      .then(async () => {
        await dispatch('account/getCustomerAccount', null, { root: true })
        await dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.create_team') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const translators = {
  namespaced: true,
  state: defaultState,
  actions,
  mutations
}
