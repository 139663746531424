import ApiEasycatService from '@/api/new/services/api.easycat.service'

const state = {
  vectors: []
}

const mutations = {
  setVectors(state, vectors) {
    state.vectors = vectors
  }
}

const actions = {
  async getVisualization({ commit, rootGetters, rootState }, payload) {
    const tm = rootState['translationMemories'].currentTm
    const target = rootState['translationMemories'].currentTmLanguage

    const params = new URLSearchParams()
    params.append('source_language', tm.attributes.source_language)
    params.append('target_language', payload.target || target)
    payload.sourceType.forEach((type) => {
      params.append('source_types[]', type)
    })
    params.append('limit', payload.limit)
    params.append('settings[dimensions]', 3)
    params.append('settings[neighbors]', payload.neighbors)
    params.append('settings[epochs]', 500)
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/${payload.id}/vectors`,
      { params }
    )
      .then((res) => {
        const data = res.data.data
        commit('setVectors', data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const vectors = {
  namespaced: true,
  state,
  actions,
  mutations
}
