import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import FileSaver from 'file-saver'

const state = {
  tasks: [],
  billingAttributes: {}
}

const mutations = {
  setTasks(state, tasks) {
    state.tasks = tasks
  },
  setBillingAttributes(state, billingAttributes) {
    state.billingAttributes = billingAttributes
  }
}

const actions = {
  async getTasks({ commit, rootGetters }, { projectId }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/tasks`
    )
      .then((res) => {
        const tasks = res.data.data
        commit('setTasks', tasks)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async rateTask({ dispatch, rootGetters }, context) {
    const data = {
      data: {
        type: 'rating',
        attributes: {
          value: context.score,
          comment: context.comment
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${context.projectId}/tasks/${context.taskId}/rate-translation`,
      data
    )
      .then(async () => {
        dispatch('getTasks', { projectId: context.projectId })
        await dispatch('project/getProject', context.projectId, { root: true })
        await dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.thanks_title'),
            message: i18n.t('customer.toast.success.rating')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadSourceFile(_, { path, fileName }) {
    const requestData = {
      method: 'get',
      url: path,
      data: {},
      responseType: 'blob'
    }

    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: res.data.type })
        )
        FileSaver.saveAs(fileSource, fileName)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async declinePrice({ dispatch, rootGetters }, { projectId }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/decline-price`
    )
      .then(() => {
        dispatch('project/getProject', projectId, { root: true })
        dispatch(
          'workspace/getProjects',
          { currentPage: 1, search: null },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptPrice({ commit, dispatch, rootGetters }, { projectId }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${projectId}/accept-price`
    )
      .then((res) => {
        commit('setBillingAttributes', res.data.data)
        if (!res.data.data) {
          dispatch(
            'toast/success',
            { message: i18n.t('You have accepted the project') },
            { root: true }
          )
          dispatch('project/getProject', projectId, { root: true })
          dispatch(
            'workspace/getProjects',
            { currentPage: 1, search: null },
            { root: true }
          )
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async reassignTask(
    { dispatch, rootState, rootGetters },
    { taskId, customerId }
  ) {
    const data = {
      data: {
        type: 'task',
        attributes: {
          reason: 'Cancelled due to unavailability from API',
          ...(customerId ? { customer_id: customerId } : {})
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${rootState.project.currentProject.id}/tasks/${taskId}/reassign`,
      data
    )
      .then(() => {
        if (!customerId)
          dispatch(
            'toast/success',
            { message: i18n.t('customer.toast.success.request_reassign') },
            { root: true }
          )
        setTimeout(async () => {
          await dispatch(
            'project/getProject',
            rootState.project.currentProject.id,
            { root: true }
          )
        }, 10000)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadTranslation({ commit, rootGetters }, payload) {
    const requestData = {
      method: 'get',
      url: `/laas/api/v1/teams/${rootGetters['workspace/currentAccountId']}/projects/${payload.projectId}/tasks/${payload.taskId}/download`,
      data: {},
      responseType: 'blob'
    }
    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: res.data.type })
        )
        FileSaver.saveAs(fileSource, payload.fileName)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const tasks = {
  namespaced: true,
  state,
  actions,
  mutations
}
